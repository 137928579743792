body {
    margin: 0;
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #F7F9FC !important;
    font-size: 14px;
}

.MuiTypography-body1 {
    font-size: 15px !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.MuiButton-textPrimary {
    color: #407BD5 !important;
}

.MuiCard-root {
    box-shadow: rgb(50 50 93 / 3%) 0px 2px 5px -1px, rgb(0 0 0 / 5%) 0px 1px 3px -1px;
}

.MuiButton-root {
    text-transform: none !important;
    box-shadow: none !important;
}

.MuiTab-root {
    text-transform: none !important;
}

.search-field .MuiOutlinedInput-input {
    font-size: 14px !important;
    padding: 12px;
}

.makeStyles-appBar-2 {
    box-shadow: none !important;
}

.mgtp-25 {
    margin-top: 25px !important;
}

.mgtp-15 {
    margin-top: 15px !important;
}

.mgbt-10 {
    margin-bottom: 10px !important;
}

.mgr-10 {
    margin-right: 10px !important;
}

.mgbt-0 {
    margin-bottom: 0 !important;
}

.pdr-12 {
    padding-right: 12px !important;
}

.pdbt-0 {
    padding-bottom: 0 !important;
}

.pdtp-0 {
    padding-top: 0 !important;
}

.sec-wrap {
    padding: 20px;
    background-color: #fff;
    box-shadow: rgb(50 50 93 / 3%) 0px 2px 5px -1px, rgb(0 0 0 / 5%) 0px 1px 3px -1px;
    border-radius: 6px;
}

.MuiAppBar-colorPrimary {
    background: #fff !important;
}

.makeStyles-menuButton-4 {
    background: transparent;
    padding: 0 !important;
    margin-right: 8px !important;
    color: #3a87c3 !important;
}

#pin-notify-wrap {
    margin: 0;
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    position: relative;
    padding: .25rem .50rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
}

#pin-notify-wrap.new-pin {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.pin-wrap button:first-child {
    margin-right: 10px;
}

.container {
    margin-top: 70px;
}

.makeStyles-logoWrap-12 h6 {
    display: flex;
}

.jss8 {
    display: none !important;
}

.makeStyles-menuButton-4 {
    margin-right: 16px !important;
}

.logo {
    width: 160px;
    padding: 20px 0;
}

.logo.lg {
    width: 250px;
    margin-bottom: 35px;
}

.burger-icon {
    color: #407BD5;
}

.account-wrap {
    display: flex;
    align-items: center;
}

.account-wrap img {
    border-radius: 50%;
    cursor: pointer;
    width: 30px;
    height: 30px;
}

.profile-pic-sm {
    width: 35px;
    height: 35px;
}

.profile-pic-lg {
    width: 100px;
    height: 100px;
}

.account-wrap .sub-links-wrap {
    display: flex;
    align-items: center;
    padding-left: 5px;
    cursor: pointer;
    position: relative;
}

.account-wrap .sub-links-wrap img {
    border-radius: 50px;
}

.sub-links-wrap .MuiIconButton-root {
    padding: 0 !important;
}

.sub-links-wrap .MuiIconButton-label .MuiSvgIcon-root {
    color: #407BD5;
}

.profile-wrap {
    position: absolute;
    bottom: -31em;
    right: 1.8%;
    width: 260px;
}

.profile-wrap .MuiCardContent-root {
    padding: 0;
}

.profile-wrap .header-content {
    padding: 20px;
    background: #407BD5;
    text-align: center;
    cursor: initial;
}

.profile-wrap .header-content img {
    border-radius: 90px;
    width: 100px;
    height: 100px;
    cursor: initial;
}

.profile-wrap .info {
    color: #fff;
}

.profile-wrap .header-content .MuiTypography-subtitle2 {
    color: #addbff;
    font-weight: normal;
}

.profile-wrap .body-content {
    padding: 20px 13px 0;
    cursor: initial;
}

.profile-wrap .body-content .label {
    color: #7a7a7a;
    font-size: 12px;
}

.profile-wrap .body-content .label {
    padding-bottom: 3px;
    border: 0;
}

.profile-wrap .MuiCardActions-root {
    padding: 5px 13px 20px;
    float: right;
}

.profile-wrap .MuiCardActions-root button:first-child {
    margin-right: 10px;
}

.profilePicWrap {
    width: 250px;
    text-align: center;
}

.profilePicWrap img {
    width: 100%;
    margin-bottom: 10px;
    cursor: pointer;
}

.uploadPhoto {
    position: absolute;
    right: 6.5em;
    top: 6.8em;
    background: #fff;
    border-radius: 100px;
    padding: 4px;
    height: 27px;
    width: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.uploadPhoto svg {
    color: #0a6cb9;
    font-size: 18px;
}

.profilePicWrap p {
    text-align: left;
    font-size: 13px;
    margin-bottom: 20px;
}

.exceed-upload {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
    padding: 3px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    font-size: 12px;
    margin-bottom: 15px;
    display: block;
}

.MuiDrawer-paper {
    background: #243144 !important;
}

.MuiListItem-button:hover {
    background-color: rgb(30, 41, 58) !important;
}

.MuiDrawer-paper .MuiListItemIcon-root {
    min-width: 40px;
}

.MuiDrawer-paper .MuiSvgIcon-root {
    color: rgba(238, 238, 238, 0.5);
}

.MuiSvgIcon-root {
    width: .9em !important;
    height: .9em !important;
}

.MuiDrawer-paper .MuiTypography-displayBlock {
    color: rgb(238, 238, 238);
    font-size: 14px !important;
}

.close-drawer-wrap {
    text-align: right;
    padding: 10px;
}

.page-icon {
    display: flex;
    padding: 16px;
    color: #3c44b1;
    align-items: center;
}

.MuiPaper-elevation1 {
    box-shadow: none !important;
    border-radius: 50% !important;
    background: #FE9900 !important;
}

.page-icon svg.MuiSvgIcon-root {
    color: #fff;
}

.makeStyles-root-15 {
    background-color: rgb(255, 244, 229) !important;
    box-shadow: rgb(50 50 93 / 3%) 0px 2px 5px -1px, rgb(0 0 0 / 5%) 0px 1px 3px -1px !important;
}

.icon-wrap svg.MuiSvgIcon-root {
    color: #fff;
}

/*alignment*/
.txt-right {
    text-align: right !important;
}

/*colors*/
.MuiButton-containedPrimary {
    background-color: #407BD5 !important;
}

.MuiButton-containedPrimary:hover {
    background-color: rgb(49, 91, 152) !important;
}

.MuiTab-textColorPrimary.Mui-selected {
    color: #fff !important;
}

.MuiTab-textColorPrimary {
    color: rgba(255, 255, 255, 0.5) !important;
    margin-bottom: -5px !important;
}

.MuiTab-textColorPrimary:hover {
    color: rgba(255, 255, 255, 1) !important;
}

.MuiTab-textColorPrimary.Mui-selected::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: #407BD5;
    z-index: 2;
}

/*login*/
.login-page .login-wrap {
    height: 100vh;
}

.login-page .makeStyles-content-9 {
    height: 100vh;
    justify-content: center;
    display: flex;
    align-items: center;
}

.login-page main {
    padding: 0 !important;
}

.login-page form {
    margin-top: 25px;
}

.login-page .MuiInputBase-root,
.login-page form .MuiButtonBase-root {
    margin-bottom: 20px;
}

.login-page .MuiGrid-root .MuiPaper-root {
    display: flex;
    align-items: center;
    justify-content: center;
}

.forgot-pwd:hover {
    text-decoration: underline;
}

.forgot-pwd {
    cursor: pointer;
}

.forgot-wrap .MuiButtonBase-root {
    margin-bottom: 0 !important;
}

.login-page .MuiDialogContent-dividers {
    padding-top: 0;
    padding-bottom: 0;
}

/*form controls*/
.MuiFormControl-root {
    width: 100%;
}

.payment-received label {
    transform: translate(0, 1.5px) scale(0.75);
}

.partner-comments-wrap .popup-label {
    background: #2f80c0;
    color: #fff;
}

.password-wrap {
    position: relative;
}

.password-wrap .toggle-password {
    position: absolute;
    right: 10px;
    top: 23px;
}

.password-wrap .toggle-password svg {
    cursor: pointer;
    width: 18px;
}

.password-wrap.header .toggle-password {
    position: absolute;
    right: 25px;
    top: 45px;
}

.toggle-password .visibility-off {
    color: #6d6d6d;
}

.multi-select .search-wrapper {
    margin-top: 13px;
    border: 0;
    border-bottom: 1px solid #949494;
    border-radius: 0;
    padding-left: 0;
}

.multi-select .search-wrapper input::placeholder {
    font-size: 16px;
    color: #9b9b9b;
}

.multi-select .optionContainer {
    border-radius: 0;
}

.multi-select .optionContainer li {
    background: #e6e6e6;
    color: #3e3e3e;
    border-radius: 0;
}

.multi-select .optionContainer li:hover {
    background: #bdbdbd;
    color: #3e3e3e;
}

.multi-select .search-wrapper span {
    background: #2f80c0;
}

/*home*/
.user ul.MuiList-root a:nth-child(5),
.user ul.MuiList-root a:nth-child(6),
.user .tbl-minpad.enquiries th:nth-child(3) {
    display: none;
}

.follow-up-comments {
    margin-bottom: -20px !important;
}

.label {
    padding-bottom: 10px;
    border-bottom: 1px solid #e4e4e4;
}

.search-filter-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*border-bottom: 1px solid #e4e4e4;*/
    padding-bottom: 15px;
}

.search-filter-wrap .label {
    border: 0;
    padding: 0;
}

.date-filter-wrap {
    position: absolute;
    right: 3.4%;
    top: 65%;
    z-index: 99;
    box-shadow: 1px 10px 25px rgb(0 0 0 / 35%);
    display: flex;
    flex-direction: column;
}

.my-commission .date-filter-wrap {
    top: 44%;
}

.date-filter-wrap button {
    border-radius: 0;
}

.button-icon .MuiSvgIcon-root {
    padding-right: 6px;
}

.view-details,
.delete-enquiry {
    color: rgba(0, 0, 0, 0.54);
    cursor: pointer;
    float: left;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.view-details:hover {
    color: #8b8c8a;
}

.delete-enquiry:hover {
    color: #fb5050;
}

.delete-enquiry {
    color: #f44336;
}

.view-details .MuiSvgIcon-root,
.delete-enquiry .MuiSvgIcon-root {
    padding-right: 5px;
}

.status {
    display: inline-block;
    border-radius: 6px;
    color: #fff;
    padding: 4px 6px;
    font-size: 11px;
    font-weight: 500;
}

.enquiry-stat {
    background: #0fa63d;
}

.lost-stat {
    background: #f44336;
}

.on-hold {
    background: #faa22a;
}

.appointment-stat {
    background: #fa2ad7;
}

.follow-up-stat {
    background: #26bdea;
}

.on-hold-stat {
    background: #4caf50;
}

.closed-stat {
    background: #e0e0e0;
    color: rgba(0, 0, 0, 0.87);
}

.tabs-root {
    flex-grow: 1;
    margin-top: 20px;
}

.tabs-root .MuiTabs-flexContainer {
    background: #376fd0;
    border: none;
    padding: 5px 0;
}

.home .MuiTypography-subtitle2 {
    font-size: 1rem;
}

.home>.MuiPaper-root {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: rgb(50 50 93 / 3%) 0px 2px 5px -1px, rgb(0 0 0 / 5%) 0px 1px 3px -1px;
}

.home .paper-root {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    flex-wrap: wrap;
}

.home .icon-wrap {
    display: flex;
    align-items: center;
    margin-right: 20px;
    width: calc(33.33% - 20px);
    padding: 20px;
    border-radius: 4px;
}

.home .icon-wrap .page-header {
    padding-left: 15px;
    display: flex;
    flex-direction: column;
}

.MuiTypography-root.MuiTypography-h6,
.home .icon-wrap .MuiTypography-subtitle2 {
    font-size: 1rem !important;
}

.home .icon-wrap .MuiTypography-root.MuiTypography-h6 {
    font-size: 14px !important;
}

.home .icon-wrap .MuiTypography-subtitle2 {
    font-weight: 600;
    font-size: 36px;
}

.home .icon-wrap:nth-child(1) .MuiTypography-subtitle2 {
    color: #ff9800;
}

.home .icon-wrap:nth-child(2) .MuiTypography-subtitle2 {
    color: #f44336;
}

.home .icon-wrap:nth-child(3) .MuiTypography-subtitle2 {
    color: #4caf50;
}

.home .icon-wrap .page-icon {
    display: flex;
    padding: 14px;
    color: #fff;
    align-items: center;
    border-radius: 100px;
}

.home .icon-wrap:nth-child(1) .page-icon {
    background: #ff9800;
}

.home .icon-wrap:nth-child(2) .page-icon {
    background: #f44336;
}

.home .icon-wrap:nth-child(3) .page-icon {
    background: #4caf50;
}

.icon-wrap:nth-child(3) {
    background: rgb(237, 247, 237);
}

.icon-wrap:nth-child(2) {
    background: rgb(253, 236, 234);
}

.icon-wrap:first-child {
    background: rgb(255, 244, 229);
}

/*enquiry activities*/
.content-wrap {
    display: flex;
    align-items: center;
}

.partner-border {
    width: 3px;
    display: block;
    background: #2ad273;
    height: 35px;
    margin-right: 8px;
}

.admin-border {
    width: 3px;
    display: block;
    background: #54c6ff;
    height: 35px;
    margin-right: 8px;
}

.salesperson-border {
    width: 3px;
    display: block;
    background: #c054ff;
    height: 35px;
    margin-right: 8px;
}

.text-wrap {
    width: 100%;
}

.text-wrap .title-wrap {
    font-weight: 600;
    color: #3c3c3c;
}

.text-wrap .comment-wrap {
    font-size: 14px;
    color: #7b7b7b;
    margin-top: 3px;
}

.text-wrap .follow-up-wrap {
    background: #faffae;
    color: #3c3c3c;
    text-align: center;
    border-radius: 50px;
    font-size: 12px;
}

.follow-up-wrap {
    background: #faffae;
    border-radius: 50px;
    color: #3c3c3c;
    font-size: 12px;
    text-align: center;
}

.viewEditForm {
    margin-bottom: 20px;
}

.addDoc,
.editDoc {
    width: 829px;
}

.viewEditForm .MuiInputBase-root {
    font-size: 14px;
}

.buttons-wrap {
    text-align: right;
    padding-top: 0 !important;
}

/*disable fields*/
.MuiInputBase-input.Mui-disabled {
    color: #212121;
}

.MuiFormLabel-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.54);
}

/*pagination*/
.no-data-notice {
    padding: 10px;
    text-align: center;
    background: rgb(253, 236, 234);
    color: rgb(97, 26, 21);
    border-radius: 4px;
    font-size: 14px;
    margin: 10px 0 !important;
}

/*popup*/
.MuiDialog-paperWidthMd {
    max-width: 100% !important;
}

.popup-title-bar {
    justify-content: space-between;
    display: flex;
    gap: 10px;
}

.popup-title-bar .MuiButton-label {
    color: #407BD5;
    font-weight: 900;
}

.popup-title-bar .MuiButton-text {
    padding: 0 !important;
    min-width: inherit;
}

.popup-title-bar .MuiButton-text:hover {
    background: transparent;
}

.popup-title-bar .MuiButton-text:hover span {
    color: #222;
}

.popup-label {
    font-weight: 600;
    margin-bottom: 10px !important;
    color: #2f80c0;
    background: #ececec;
    padding: 6px 10px;
    margin-top: 10px !important;
}

.bg-secondary {
    background: #faffae;
}

/*my commission*/
.search-field .MuiToolbar-gutters {
    padding-left: 10px;
    margin-bottom: 10px;
}

.search-field .MuiTextField-root {
    width: 250px;
}

.tbl-minpad .MuiTableCell-head,
.tbl-minpad .MuiTableCell-body {
    padding: 10px;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #407BD5 !important;
}

.MuiFormLabel-root.Mui-focused {
    color: #407BD5 !important;
}

/**Calendar**/
.MuiButton-containedSecondary {
    background-color: #2c3e50 !important;
}

.MuiButton-containedSizeSmall {
    padding: 6px 10px !important;
}

/**/
.MuiInputBase-input,
.MuiInputBase-input::placeholder {
    font-size: 15px !important;
}

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover,
.MuiListItem-button:hover {
    background-color: rgba(0, 0, 0, 0.08) !important;
}

.MuiInput-underline:after {
    border-bottom: 2px solid #407BD5 !important;
}

.makeStyles-root-13,
.makeStyles-root-32 {
    background-color: #FFF4E6 !important;
    box-shadow: rgb(50 50 93 / 3%) 0px 2px 5px -1px, rgb(0 0 0 / 5%) 0px 1px 3px -1px !important;
}

.payment-enquiry-pop-wrap {
    margin: 1rem 0;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
}

.flex-col {
    flex-direction: column;
}

.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.link-button svg {
    font-size: 15px;
    color: blue;
}

/*media queries*/
@media only screen and (max-width: 1064px) {

    /*home*/
    .MuiTabs-flexContainer {
        width: 860px;
    }

    .home .icon-wrap .MuiTypography-subtitle2 {
        font-size: 28px;
    }

}

@media only screen and (max-width: 959px) {

    /*login*/
    .login-wrap>div:first-child {
        display: none;
    }

    .login-page .MuiGrid-grid-sm-8 {
        flex-basis: 100%;
        max-width: 100%;
    }

    .login-page main {
        margin-left: 0;
    }

    .login-page main>div {
        padding: 0;
    }

}

@media only screen and (max-width: 964px) {
    .MuiTabs-flexContainer {
        width: 100%;
    }

    .tabs-root .MuiTabs-flexContainer {
        display: flex;
        flex-direction: column;
    }

    .tabs-root button {
        width: 100%;
        max-width: 100%;
    }

    .tbl-minpad {
        width: 780px;
        overflow: auto;
    }

    .tbl-minpad .table {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
    }

    .payment-enquiry-pop-wrap,
    .info-popup-wrap .MuiGrid-root.MuiGrid-container {
        flex-direction: column;
    }

    .info-popup-wrap .MuiGrid-grid-xs-3,
    .payment-enquiry-pop-wrap .MuiGrid-item {
        max-width: 100%;
    }

    .payment-enquiry-pop-wrap .MuiGrid-grid-xs-6 {
        max-width: 100%;
        flex-basis: 100%;
    }

    .payment-enquiry-pop-wrap .tbl-minpad {
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 932px) {
    .tbl-minpad {
        width: 750px;
        overflow: auto;
    }

    .addDoc,
    .editDoc {
        width: 100%;
    }
}

@media only screen and (max-width: 902px) {
    .tbl-minpad {
        width: 710px;
    }
}

@media only screen and (max-width: 879px) {
    .home .icon-wrap {
        padding: 10px;
    }

    .home .icon-wrap .page-icon {
        padding: 8px;
    }
}

@media only screen and (max-width: 862px) {
    .tbl-minpad {
        width: 680px;
    }
}

@media only screen and (max-width: 832px) {
    .tbl-minpad {
        width: 640px;
    }
}

@media only screen and (max-width: 792px) {
    .tbl-minpad {
        width: 620px;
    }

    .home .paper-root {
        flex-direction: column;
    }

    .home .icon-wrap {
        width: 100%;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 772px) {
    .tbl-minpad {
        width: 580px;
    }
}

@media only screen and (max-width: 732px) {
    .tbl-minpad {
        width: 530px;
    }
}

@media only screen and (max-width: 682px) {
    .MuiBox-root {
        padding: 15px 0 !important;
    }

    .tbl-minpad {
        width: 480px;
    }
}

@media only screen and (max-width: 584px) {
    .tbl-minpad {
        width: 450px;
    }

    .viewEditForm.partner .MuiGrid-root {
        flex-direction: column;
    }

    .viewEditForm.partner .MuiGrid-item {
        max-width: 100%;
    }

    .MuiDialog-paper.MuiDialog-paperScrollPaper {
        width: 500px !important;
    }
}

@media only screen and (max-width: 554px) {
    .tbl-minpad {
        width: 400px;
    }

    .search-field .search-filter-wrap .MuiToolbar-root {
        margin-bottom: 0;
    }

    .search-filter-wrap button {
        font-size: 12px;
    }
}

@media only screen and (max-width: 499px) {
    .search-filter-wrap .MuiTypography-subtitle2 {
        margin-bottom: 10px;
    }

    .search-filter-wrap,
    .editDoc .MuiGrid-root {
        flex-direction: column;
    }

    .tbl-minpad {
        width: 260px;
    }

    .date-filter-wrap {
        top: 88%;
    }

    .enquiry-tbl-wrap .MuiGrid-root {
        flex-direction: column;
    }

    .enquiry-tbl-wrap .MuiGrid-grid-xs-6,
    .editDoc .MuiGrid-item {
        max-width: 100%;
    }

    .MuiGrid-root .MuiGrid-item {
        max-width: 100%;
    }

    form .MuiGrid-root {
        flex-direction: column;
    }

}